import { immerable } from 'immer';
import { CategoryDataModel, CategoryDataModelProps } from './CategoryDataModel';
export { CategoryDataModelProps };
export class CategoriesModel {
    constructor(props) {
        props
            ? (this._list = new Map(props.categories.map((val) => [val.id, new CategoryDataModel(val)])))
            : (this._list = new Map());
        this[immerable] = true;
    }
    get list() {
        return Array.from(this._list).map(([_, val]) => ({
            id: val.id,
            count: val.count,
            name: val.name,
        }));
    }
}
