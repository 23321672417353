import { immerable } from 'immer';
import { ScheduleModel, ScheduleModelProps } from './ScheduleModel';
export { ScheduleModelProps };
export class SchedulesModel {
    constructor(props) {
        props
            ? (this._list = new Map(props.schedules.map((elm) => [
                elm.id,
                new ScheduleModel(elm),
            ])))
            : (this._list = new Map());
        this[immerable] = true;
    }
    get list() {
        return Array.from(this._list).map(([_, elm]) => ({
            id: elm.id,
            date: elm.date,
            start: elm.start,
            end: elm.end,
            userName: elm.userName,
            title: elm.title,
            content: elm.content,
            zoomUrl: elm.zoomUrl,
            zoomId: elm.zoomId,
            zoomPass: elm.zoomPass
        }));
    }
}
