import { immerable } from 'immer';
import { WithdrawalModel, WithdrawalModelProps } from './WithdrawalModel';
export { WithdrawalModelProps };
export class WithdrawalsModel {
    constructor(props) {
        props
            ? (this._list = new Map(props.withdrawals.map((elm) => [
                elm.userId,
                new WithdrawalModel(elm),
            ])))
            : (this._list = new Map());
        this[immerable] = true;
    }
    get list() {
        return Array.from(this._list).map(([_, elm]) => ({
            userId: elm.userId,
            userName: elm.userName,
            emailAddress: elm.emailAddress,
            labTwitterId: elm.labTwitterId
        }));
    }
}
