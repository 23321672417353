import { createSlice } from '@reduxjs/toolkit';
import { CategoriesModel, PostsModel } from '~/models';
export const initialState = {
    isLoading: false,
    categories: new CategoriesModel(),
    posts: new PostsModel(),
    postId: undefined
};
const wordPresssSlice = createSlice({
    name: 'wordpress',
    initialState,
    reducers: {
        init(state) {
            state.posts = new PostsModel();
        },
        requestStart(state) {
            state.isLoading = true;
        },
        requestSuccess(state) {
            state.isLoading = false;
        },
        requestError(state) {
            state.isLoading = false;
        },
        setCategories(state, action) {
            state.categories = new CategoriesModel(action.payload);
        },
        setPosts(state, action) {
            state.posts = new PostsModel(action.payload);
        },
        addPosts(state, action) {
            state.posts.add(action.payload);
        },
        setPostId(state, action) {
            state.postId = action.payload;
        },
    },
});
export const wordPresssAction = wordPresssSlice.actions;
export default wordPresssSlice.reducer;
