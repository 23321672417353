import { pageAction } from '~/redux/actions';
export default class PageService {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }
    setTitle(param) {
        this.dispatch(pageAction.set(param));
    }
    modalOpen(message) {
        this.dispatch(pageAction.modalOpen({ message }));
    }
    modalClear() {
        this.dispatch(pageAction.modalClose());
    }
    confirmModalOpen(message, func) {
        this.dispatch(pageAction.confirmModalOpen({ message, func }));
    }
    confirmModalClear() {
        this.dispatch(pageAction.confirmModalClose());
    }
    imageModalOpen(src) {
        this.dispatch(pageAction.imageModalOpen({ src }));
    }
    imageModalClear() {
        this.dispatch(pageAction.imageModalClose());
    }
    postModalOpen(title, author, imageUrl, content) {
        this.dispatch(pageAction.postModalOpen({ title: title, author: author, imageUrl: imageUrl, content: content }));
    }
    postModalClear() {
        this.dispatch(pageAction.postModalClose());
    }
    scheduleModalOpen() {
        this.dispatch(pageAction.scheduleModalOpen());
    }
    scheduleModalClose() {
        this.dispatch(pageAction.scheduleModalClose());
    }
}
