import { twitterRepository } from '~/repositories';
import { twittersAction } from '~/redux/actions';
import { TwitterModel } from '~/models/TwitterModel';
export default class TwitterService {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }
    async fetchTwitter(id) {
        this.dispatch(twittersAction.requestStart());
        const data = await twitterRepository.getTwitterAcc(id).catch((e) => {
            this.dispatch(twittersAction.requestError());
            throw e;
        });
        this.dispatch(twittersAction.requestSuccess());
        return new TwitterModel(data.user);
    }
    async getTimeLine() {
        this.dispatch(twittersAction.requestStart());
        const data = await twitterRepository.getTimeLine().catch((e) => {
            this.dispatch(twittersAction.requestError());
            throw e;
        });
        this.dispatch(twittersAction.settweets({ tweets: data }));
        this.dispatch(twittersAction.requestSuccess());
    }
    async removeTweet(id) {
        this.dispatch(twittersAction.requestStart());
        const data = await twitterRepository.removeTweet(id).catch((e) => {
            this.dispatch(twittersAction.requestError());
            throw e;
        });
        this.dispatch(twittersAction.removetweet(id));
        this.dispatch(twittersAction.requestSuccess());
    }
    async memberFollowCheck() {
        this.dispatch(twittersAction.requestStart());
        const data = await twitterRepository.memberFollowCheck().catch((e) => {
            this.dispatch(twittersAction.requestError());
            throw e;
        });
        this.dispatch(twittersAction.requestSuccess());
        return data;
    }
    async memberFollow() {
        this.dispatch(twittersAction.requestStart());
        const data = await twitterRepository.memberFollow().catch((e) => {
            this.dispatch(twittersAction.requestError());
            throw e;
        });
        this.dispatch(twittersAction.requestSuccess());
    }
}
