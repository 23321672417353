import { userRepository } from '~/repositories';
import { newersAction, usersAction } from '~/redux/actions';
export default class UserService {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }
    async registration(form) {
        this.dispatch(newersAction.requestStart());
        await userRepository
            .registration(form)
            .catch((e) => {
            this.dispatch(newersAction.requestError());
            throw e;
        });
        this.dispatch(newersAction.requestSuccess());
    }
    async initUser() {
        this.dispatch(usersAction.requestStart());
        const data = await userRepository
            .findUser()
            .catch((e) => {
            this.dispatch(usersAction.requestError());
            throw e;
        });
        this.dispatch(usersAction.init({
            userId: data.userId,
            userName: data.userName,
            emailAddress: data.emailAddress,
            country: data.country,
            labId: data.labId,
            labTwitterId: data.labTwitterId,
            twitterId: data.twitterId,
            twitterUK: data.twitterUK,
            instagramId: data.instagramId,
            role: data.role
        }));
        this.dispatch(usersAction.requestSuccess());
        return data;
    }
    async sync(val) {
        this.dispatch(usersAction.requestStart());
        await userRepository
            .sync(val)
            .catch((e) => {
            this.dispatch(usersAction.requestError());
            throw e;
        });
        this.dispatch(usersAction.requestSuccess());
    }
    async updateTwitter(userId, twitterId) {
        this.dispatch(usersAction.requestStart());
        const data = await userRepository
            .updateTwitter(userId, twitterId)
            .catch((e) => {
            this.dispatch(usersAction.requestError());
            throw e;
        });
        this.dispatch(usersAction.updateTwitter({ twiterId: data.twitterId, twitterUk: data.twitterUk }));
        this.dispatch(usersAction.requestSuccess());
    }
    async findAll() {
        this.dispatch(usersAction.requestStart());
        const data = await userRepository
            .findAll()
            .catch((e) => {
            this.dispatch(usersAction.requestError());
            throw e;
        });
        this.dispatch(usersAction.fetchMembers({ members: data }));
        this.dispatch(usersAction.requestSuccess());
        return data;
    }
}
