import { configureStore, combineReducers, getDefaultMiddleware, } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import error from './modules/error';
import followers from './modules/follower';
import twitter from './modules/twitter';
import upload from './modules/upload';
import page from './modules/page';
import withdrawal from './modules/withdrawal';
import newer from './modules/newer';
import wordPress from './modules/wordPress';
import user from './modules/user';
import auth from './modules/auth';
import schedule from './modules/schedule';
const rootReducer = combineReducers({
    error,
    page,
    followers,
    twitter,
    upload,
    withdrawal,
    newer,
    wordPress,
    user,
    auth,
    schedule
});
const store = configureStore({
    reducer: rootReducer,
    middleware: [
        ...getDefaultMiddleware({
            serializableCheck: false,
        }),
        logger,
    ],
});
export default store;
