import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    title: '',
    bodyFix: false,
    message: undefined,
    confirmModalModel: undefined,
    postModal: undefined,
    scheduleModal: false
};
const pageSlice = createSlice({
    name: 'page',
    initialState,
    reducers: {
        set(state, action) {
            state.title = action.payload.title;
        },
        modalOpen(state, action) {
            state.message = action.payload.message;
        },
        modalClose(state) {
            state.message = undefined;
        },
        confirmModalOpen(state, action) {
            state.confirmModalModel = action.payload;
        },
        confirmModalClose(state) {
            state.confirmModalModel = undefined;
        },
        imageModalOpen(state, action) {
            state.imageModal = action.payload;
        },
        imageModalClose(state) {
            state.imageModal = undefined;
        },
        postModalOpen(state, action) {
            state.postModal = action.payload;
            state.bodyFix = true;
        },
        postModalClose(state) {
            state.postModal = undefined;
            state.bodyFix = false;
        },
        scheduleModalOpen(state) {
            state.scheduleModal = true;
            state.bodyFix = true;
        },
        scheduleModalClose(state) {
            state.scheduleModal = false;
            state.bodyFix = false;
        },
    },
});
export const pageAction = pageSlice.actions;
export default pageSlice.reducer;
