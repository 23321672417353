import { withdrawalRepository } from '~/repositories';
import { withdrawalsAction } from '~/redux/actions';
export default class WithdrawalService {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }
    async complete(param) {
        this.dispatch(withdrawalsAction.requestStart());
        await withdrawalRepository
            .complete({ withdrawalForm: { userId: param.userId, labTwitter: param.labTwitterId } })
            .catch((e) => {
            this.dispatch(withdrawalsAction.requestError());
            throw e;
        });
        this.dispatch(withdrawalsAction.requestSuccess());
    }
    async check(param) {
        this.dispatch(withdrawalsAction.requestStart());
        await withdrawalRepository
            .check({ withdrawalForm: { userId: param.userId, labTwitter: param.labTwitterId } })
            .catch((e) => {
            this.dispatch(withdrawalsAction.requestError());
            throw e;
        });
        this.dispatch(withdrawalsAction.requestSuccess());
    }
    async cs(param) {
        this.dispatch(withdrawalsAction.requestStart());
        await withdrawalRepository
            .cs(param)
            .catch((e) => {
            this.dispatch(withdrawalsAction.requestError());
            throw e;
        });
        this.dispatch(withdrawalsAction.requestSuccess());
    }
    async findAll() {
        this.dispatch(withdrawalsAction.requestStart());
        const data = await withdrawalRepository
            .find()
            .catch((e) => {
            this.dispatch(withdrawalsAction.requestError());
            throw e;
        });
        this.dispatch(withdrawalsAction.fetch({ withdrawals: data }));
        this.dispatch(withdrawalsAction.requestSuccess());
        return data;
    }
}
