import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    isLoading: false,
};
const newersSlice = createSlice({
    name: 'newer',
    initialState,
    reducers: {
        requestStart(state) {
            state.isLoading = true;
        },
        requestSuccess(state) {
            state.isLoading = false;
        },
        requestError(state) {
            state.isLoading = false;
        },
    },
});
export const newersAction = newersSlice.actions;
export default newersSlice.reducer;
