import { Configuration, WordPressControllerApi, PostDataModel, CategoryModel } from '~/lib/apiClient';
export { PostDataModel, CategoryModel };
const config = new Configuration({
    basePath: process.env.API_URL,
});
const wordPressApi = new WordPressControllerApi(config);
export const wordPressRepository = {
    findPost: (id) => wordPressApi.findPost({ id: id }),
    findCategories: () => wordPressApi.findCategories(),
    findPostByCategories: (categoryId, page) => wordPressApi.findPostByCategories({ categoryId: categoryId, page: page }),
};
