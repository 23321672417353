import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    isLoading: false
};
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        requestStart(state) {
            state.isLoading = true;
        },
        requestSuccess(state) {
            state.isLoading = false;
        },
        requestError(state) {
            state.isLoading = false;
        },
    },
});
export const authAction = authSlice.actions;
export default authSlice.reducer;
