/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { WithdrawalCSFormToJSON, WithdrawalDataModelFromJSON, WithdrawalFormToJSON, } from '../models';
/**
 *
 */
export class WithdrawalControllerApi extends runtime.BaseAPI {
    /**
     */
    async checkRaw(requestParameters) {
        if (requestParameters.withdrawalForm === null || requestParameters.withdrawalForm === undefined) {
            throw new runtime.RequiredError('withdrawalForm', 'Required parameter requestParameters.withdrawalForm was null or undefined when calling check.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/withdrawal/check`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WithdrawalFormToJSON(requestParameters.withdrawalForm),
        });
        return new runtime.TextApiResponse(response);
    }
    /**
     */
    async check(requestParameters) {
        const response = await this.checkRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async findNotFinishListRaw() {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/withdrawal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WithdrawalDataModelFromJSON));
    }
    /**
     */
    async findNotFinishList() {
        const response = await this.findNotFinishListRaw();
        return await response.value();
    }
    /**
     */
    async withdrawalCompleteRaw(requestParameters) {
        if (requestParameters.withdrawalForm === null || requestParameters.withdrawalForm === undefined) {
            throw new runtime.RequiredError('withdrawalForm', 'Required parameter requestParameters.withdrawalForm was null or undefined when calling withdrawalComplete.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/withdrawal/complete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WithdrawalFormToJSON(requestParameters.withdrawalForm),
        });
        return new runtime.TextApiResponse(response);
    }
    /**
     */
    async withdrawalComplete(requestParameters) {
        const response = await this.withdrawalCompleteRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async withdrawalCsRaw(requestParameters) {
        if (requestParameters.withdrawalCSForm === null || requestParameters.withdrawalCSForm === undefined) {
            throw new runtime.RequiredError('withdrawalCSForm', 'Required parameter requestParameters.withdrawalCSForm was null or undefined when calling withdrawalCs.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/withdrawal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WithdrawalCSFormToJSON(requestParameters.withdrawalCSForm),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async withdrawalCs(requestParameters) {
        const response = await this.withdrawalCsRaw(requestParameters);
        return await response.value();
    }
}
