import { Configuration } from '~/lib/apiClient';
const config = new Configuration({
    basePath: process.env.API_URL,
});
export const authRepository = {
    login: async (username, password) => {
        const data = new FormData();
        data.set('username', username);
        data.set('password', password);
        await fetch(config.basePath + '/login', {
            method: 'POST',
            credentials: 'include',
            body: data
        })
            .then((res) => {
            if (!res.ok) {
                throw new Error(`${res.status} ${res.statusText}`);
            }
            return res.blob();
        });
    },
};
