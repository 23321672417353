import { immerable } from 'immer';
export class PostModel {
    constructor({ id, title, content, featuredImageUrl, authorId, authorName, category }) {
        this[immerable] = true;
        this.id = id;
        this.title = title;
        this.content = content;
        this.featuredImageUrl = featuredImageUrl;
        this.authorId = authorId;
        this.authorName = authorName;
        this.category = category;
    }
}
