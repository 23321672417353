import { createSlice } from '@reduxjs/toolkit';
import { SchedulesModel, } from '~/models';
export const initialState = {
    isLoading: false,
    entity: new SchedulesModel(),
};
const schedulesSlice = createSlice({
    name: 'schedules',
    initialState,
    reducers: {
        requestStart(state) {
            state.isLoading = true;
        },
        requestSuccess(state) {
            state.isLoading = false;
        },
        requestError(state) {
            state.isLoading = false;
        },
        set(state, action) {
            state.entity = new SchedulesModel(action.payload);
        },
    },
});
export const schedulesAction = schedulesSlice.actions;
export default schedulesSlice.reducer;
