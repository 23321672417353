/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { PayPalSubscriberFromJSON, StripeSubScribeFormToJSON, SubscriptionModelFromJSON, } from '../models';
/**
 *
 */
export class PaymentControllerApi extends runtime.BaseAPI {
    /**
     */
    async createCustomerRaw(requestParameters) {
        if (requestParameters.stripeSubScribeForm === null || requestParameters.stripeSubScribeForm === undefined) {
            throw new runtime.RequiredError('stripeSubScribeForm', 'Required parameter requestParameters.stripeSubScribeForm was null or undefined when calling createCustomer.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/payment/stripe/subscriber`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StripeSubScribeFormToJSON(requestParameters.stripeSubScribeForm),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async createCustomer(requestParameters) {
        const response = await this.createCustomerRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async findPaypalUserRaw(requestParameters) {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email', 'Required parameter requestParameters.email was null or undefined when calling findPaypalUser.');
        }
        const queryParameters = {};
        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/payment/user/paypal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => PayPalSubscriberFromJSON(jsonValue));
    }
    /**
     */
    async findPaypalUser(requestParameters) {
        const response = await this.findPaypalUserRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async findStripeUserRaw(requestParameters) {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email', 'Required parameter requestParameters.email was null or undefined when calling findStripeUser.');
        }
        const queryParameters = {};
        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/payment/user/stripe`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionModelFromJSON(jsonValue));
    }
    /**
     */
    async findStripeUser(requestParameters) {
        const response = await this.findStripeUserRaw(requestParameters);
        return await response.value();
    }
}
