/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function UserTwitterDataModelFromJSON(json) {
    return UserTwitterDataModelFromJSONTyped(json, false);
}
export function UserTwitterDataModelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'twitterId': !exists(json, 'twitterId') ? undefined : json['twitterId'],
        'twitterUk': !exists(json, 'twitterUk') ? undefined : json['twitterUk'],
    };
}
export function UserTwitterDataModelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userId': value.userId,
        'twitterId': value.twitterId,
        'twitterUk': value.twitterUk,
    };
}
