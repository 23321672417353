import { Configuration, UserControllerApi, UserRegistrationForm } from '~/lib/apiClient';
export { UserRegistrationForm };
const config = new Configuration({
    basePath: process.env.API_URL,
});
const api = new UserControllerApi(config);
export const userRepository = {
    registration: (param) => api.registration({ dzlKey: 'aaaa', userRegistrationForm: param }),
    findUser: () => api.find(),
    sync: (id) => api.sync({ id: id }),
    updateTwitter: (userId, twitterId) => api.updateTwitter({ userUpdateTwitterForm: { userId: userId, twitterId: twitterId } }),
    findAll: () => api.findAll(),
};
