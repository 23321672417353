/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { FollowersDataFromJSON, } from '../models';
/**
 *
 */
export class FollowerControllerApi extends runtime.BaseAPI {
    /**
     */
    async createRaw() {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/follower`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => FollowersDataFromJSON(jsonValue));
    }
    /**
     */
    async create() {
        const response = await this.createRaw();
        return await response.value();
    }
    /**
     */
    async get1Raw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling get1.');
        }
        const queryParameters = {};
        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/follower/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => FollowersDataFromJSON(jsonValue));
    }
    /**
     */
    async get1(requestParameters) {
        const response = await this.get1Raw(requestParameters);
        return await response.value();
    }
}
