import { createSlice } from '@reduxjs/toolkit';
import { MembersModel } from '~/models/MembersModel';
import { UserDataModel } from '~/models/UserDataModel';
export const initialState = {
    isLoading: false,
    entity: undefined,
    members: new MembersModel()
};
const usersSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        init(state, action) {
            state.entity = new UserDataModel(action.payload);
        },
        requestStart(state) {
            state.isLoading = true;
        },
        requestSuccess(state) {
            state.isLoading = false;
        },
        requestError(state) {
            state.isLoading = false;
        },
        updateTwitter(state, action) {
            state.entity = new UserDataModel({
                userId: state.entity.userId,
                emailAddress: state.entity.emailAddress,
                userName: state.entity.userName,
                country: state.entity.country,
                labId: state.entity.labId,
                labTwitterId: state.entity.labTwitterId,
                twitterId: action.payload.twiterId,
                twitterUK: action.payload.twitterUk,
                instagramId: state.entity.instagramId,
                role: state.entity.role,
            });
        },
        fetchMembers(state, action) {
            state.members = new MembersModel(action.payload);
        },
    },
});
export const usersAction = usersSlice.actions;
export default usersSlice.reducer;
