/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function StripeSubScribeFormFromJSON(json) {
    return StripeSubScribeFormFromJSONTyped(json, false);
}
export function StripeSubScribeFormFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'stripeToken': !exists(json, 'stripeToken') ? undefined : json['stripeToken'],
        'planId': !exists(json, 'planId') ? undefined : json['planId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'nickName': !exists(json, 'nickName') ? undefined : json['nickName'],
    };
}
export function StripeSubScribeFormToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'stripeToken': value.stripeToken,
        'planId': value.planId,
        'email': value.email,
        'name': value.name,
        'nickName': value.nickName,
    };
}
