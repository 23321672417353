import { immerable } from 'immer';
export class MemberModel {
    constructor({ userId, userName, country, labTwitterId, twitterId, instagramId }) {
        this[immerable] = true;
        this.userId = userId;
        this.userName = userName;
        this.country = country;
        this.labTwitterId = labTwitterId;
        this.twitterId = twitterId;
        this.instagramId = instagramId;
    }
}
