import { createGlobalStyle } from 'styled-components';
const GlobalStyle = createGlobalStyle `
  // reset
  html, body, div, span, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  abbr, address, cite, code,
  del, dfn, em, img, ins, kbd, q, samp,
  small, strong, sub, sup, var, b, i,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }

  * {
    box-sizing: border-box;
  }

  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    background-color: #EBECF0;
    font-family: sans-serif;
    font-size: 1rem;
    line-height: 1.6;
}

  }

  article,aside,details,figcaption,figure,
  footer,header,hgroup,menu,nav,section {
    display: block;
  }

  nav ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }

  del {
    text-decoration: line-through;
  }

  abbr[title], dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
  }

  input, select {
    vertical-align: middle;
  }

  // base
  body {
    color: #333;
  }

  #root {
    width: 100%;
    height: 100;
  }

  .post_content {
    width: 95% !important;
    margin: 0 auto; 
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Segoe UI,Hiragino Kaku Gothic ProN,Hiragino Sans,ヒラギノ角ゴ ProN W3,Arial,メイリオ,Meiryo,sans-serif;
  }

  .post_content img {
    width: 50%;
    height: auto;
    margin: 0 auto;
    display: block;
    margin-bottom: 30px;
  }

  .teach-icon {
    width: 100px !important;
  }

  @media screen and (max-width: 768px) {
    .post_content img {
      width: 100%;
      height: auto;
    }
  }

  .post_content p {
    margin-bottom: 30px;
  }

  .post_content iframe {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    .post_content iframe {
      width: 100%;
      margin-top: -80px;
    }
  }

  .post_content h1 {
    margin-bottom: 20px;
    font-size: 15pt;
    text-align: center;
    
  }

  .post_content blockquote {
    margin: 0 0 25px 0;
    padding: 27px 30px 0;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 0px 0px #f2f2f2;
    position: relative;
  }

  .post_content blockquote:before {
    content: '"';
    font-style: italic;
    font-size: 30px;
    font-weight: normal;
    line-height: 40px;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 5px;
    left: 10px;
    color: #5cbcd7;
  }

  .post_content .marker-animation {
    display: inline;
    background-position: left -100% center;
    padding-bottom: 0.6em;
    font-weight: bold;
    background-size: 200% 0.6em;
    background-repeat: repeat-x;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, rgb(232, 213, 255) 50%);
}

  .post_content h2 {
    margin: 50px 0 15px;
    padding: 5px 0;
    font-weight: 700;
    border-bottom: 2px solid #181818;
  }

  .post_content h3 {
    margin: 20px 0 20px;
    padding: 13px 14px 12px;
    font-weight: 700;
    background: rgba(225, 225, 225, 0.8);
    border-radius: 10px;
  }
`;
export default GlobalStyle;
