/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { SchedulesResponseFromJSON, } from '../models';
/**
 *
 */
export class ScheduleControllerApi extends runtime.BaseAPI {
    /**
     */
    async getRaw(requestParameters) {
        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date', 'Required parameter requestParameters.date was null or undefined when calling get.');
        }
        const queryParameters = {};
        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/schedules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesResponseFromJSON(jsonValue));
    }
    /**
     */
    async get(requestParameters) {
        const response = await this.getRaw(requestParameters);
        return await response.value();
    }
}
