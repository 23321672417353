import { Configuration, WithdrawalControllerApi } from '~/lib/apiClient';
const config = new Configuration({
    basePath: process.env.API_URL,
});
const withdrawalApi = new WithdrawalControllerApi(config);
export const withdrawalRepository = {
    complete: (param) => withdrawalApi.withdrawalComplete(param),
    check: (param) => withdrawalApi.check(param),
    cs: (param) => withdrawalApi.withdrawalCs({ withdrawalCSForm: param }),
    find: () => withdrawalApi.findNotFinishList()
};
