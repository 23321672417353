import { immerable } from 'immer';
export class WithdrawalModel {
    constructor({ userId, userName, emailAddress, labTwitterId }) {
        this[immerable] = true;
        this.userId = userId;
        this.userName = userName;
        this.emailAddress = emailAddress;
        this.labTwitterId = labTwitterId;
    }
}
