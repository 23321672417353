/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { TweetDataFromJSON, UserResponseFromJSON, } from '../models';
/**
 *
 */
export class TwitterControllerApi extends runtime.BaseAPI {
    /**
     */
    async memberFollowRaw() {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/twitter/member_follow`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async memberFollow() {
        const response = await this.memberFollowRaw();
        return await response.value();
    }
    /**
     */
    async memberFollowCheckRaw() {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/twitter/member_follow_check`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.TextApiResponse(response);
    }
    /**
     */
    async memberFollowCheck() {
        const response = await this.memberFollowCheckRaw();
        return await response.value();
    }
    /**
     */
    async removePostRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling removePost.');
        }
        const queryParameters = {};
        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/twitter/remove`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.TextApiResponse(response);
    }
    /**
     */
    async removePost(requestParameters) {
        const response = await this.removePostRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async timelineRaw() {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/twitter/timeline`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TweetDataFromJSON));
    }
    /**
     */
    async timeline() {
        const response = await this.timelineRaw();
        return await response.value();
    }
    /**
     */
    async userRaw(requestParameters) {
        if (requestParameters.screenName === null || requestParameters.screenName === undefined) {
            throw new runtime.RequiredError('screenName', 'Required parameter requestParameters.screenName was null or undefined when calling user.');
        }
        const queryParameters = {};
        if (requestParameters.screenName !== undefined) {
            queryParameters['screenName'] = requestParameters.screenName;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/twitter/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseFromJSON(jsonValue));
    }
    /**
     */
    async user(requestParameters) {
        const response = await this.userRaw(requestParameters);
        return await response.value();
    }
}
