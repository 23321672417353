import { schedulesAction } from '~/redux/actions';
import { scheduleRepository } from '~/repositories';
export default class ScheduleService {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }
    async fetch(date) {
        this.dispatch(schedulesAction.requestStart());
        const data = await scheduleRepository
            .find(date)
            .catch((e) => {
            this.dispatch(schedulesAction.requestError());
            throw e;
        });
        this.dispatch(schedulesAction.set({ schedules: data.list ? data.list : [] }));
        this.dispatch(schedulesAction.requestSuccess());
    }
}
