import { createSlice } from '@reduxjs/toolkit';
import { FollowersModel } from '~/models';
export const initialState = {
    isLoading: false,
    entity: new FollowersModel(),
};
const followersSlice = createSlice({
    name: 'followers',
    initialState,
    reducers: {
        init(state) {
            state.entity = new FollowersModel();
        },
        requestStart(state) {
            state.isLoading = true;
        },
        requestSuccess(state) {
            state.isLoading = false;
        },
        requestError(state) {
            state.isLoading = false;
        },
        setFollowers(state, action) {
            state.entity = new FollowersModel(action.payload);
        },
    },
});
export const followersAction = followersSlice.actions;
export default followersSlice.reducer;
