/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function WithdrawalDataModelFromJSON(json) {
    return WithdrawalDataModelFromJSONTyped(json, false);
}
export function WithdrawalDataModelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'labTwitterId': !exists(json, 'labTwitterId') ? undefined : json['labTwitterId'],
    };
}
export function WithdrawalDataModelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userId': value.userId,
        'userName': value.userName,
        'emailAddress': value.emailAddress,
        'labTwitterId': value.labTwitterId,
    };
}
