import { useDispatch } from 'react-redux';
import { ErrorService, PageService, FollowerService, TwitterSerivce, UploadService, WithdrawalService, PaymentService, UserService, WordPressService, AuthService, ScheduleService } from '~/services';
export default () => {
    return {
        errorService: new ErrorService(useDispatch()),
        pageService: new PageService(useDispatch()),
        followerService: new FollowerService(useDispatch()),
        twitterSerivce: new TwitterSerivce(useDispatch()),
        uploadService: new UploadService(useDispatch()),
        withdrawalService: new WithdrawalService(useDispatch()),
        paymentService: new PaymentService(useDispatch()),
        userService: new UserService(useDispatch()),
        wordPressService: new WordPressService(useDispatch()),
        authService: new AuthService(useDispatch()),
        scheduleService: new ScheduleService(useDispatch())
    };
};
