/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { UserDisplayModelFromJSON, UserModelFromJSON, UserRegistrationFormToJSON, UserTwitterDataModelFromJSON, UserUpdateTwitterFormToJSON, } from '../models';
/**
 *
 */
export class UserControllerApi extends runtime.BaseAPI {
    /**
     */
    async findRaw() {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/login`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => UserModelFromJSON(jsonValue));
    }
    /**
     */
    async find() {
        const response = await this.findRaw();
        return await response.value();
    }
    /**
     */
    async findAllRaw() {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserDisplayModelFromJSON));
    }
    /**
     */
    async findAll() {
        const response = await this.findAllRaw();
        return await response.value();
    }
    /**
     */
    async registrationRaw(requestParameters) {
        if (requestParameters.dzlKey === null || requestParameters.dzlKey === undefined) {
            throw new runtime.RequiredError('dzlKey', 'Required parameter requestParameters.dzlKey was null or undefined when calling registration.');
        }
        if (requestParameters.userRegistrationForm === null || requestParameters.userRegistrationForm === undefined) {
            throw new runtime.RequiredError('userRegistrationForm', 'Required parameter requestParameters.userRegistrationForm was null or undefined when calling registration.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.dzlKey !== undefined && requestParameters.dzlKey !== null) {
            headerParameters['dzlKey'] = String(requestParameters.dzlKey);
        }
        const response = await this.request({
            path: `/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserRegistrationFormToJSON(requestParameters.userRegistrationForm),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async registration(requestParameters) {
        const response = await this.registrationRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async syncRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling sync.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/sync/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async sync(requestParameters) {
        const response = await this.syncRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async updateTwitterRaw(requestParameters) {
        if (requestParameters.userUpdateTwitterForm === null || requestParameters.userUpdateTwitterForm === undefined) {
            throw new runtime.RequiredError('userUpdateTwitterForm', 'Required parameter requestParameters.userUpdateTwitterForm was null or undefined when calling updateTwitter.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/twitter`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateTwitterFormToJSON(requestParameters.userUpdateTwitterForm),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => UserTwitterDataModelFromJSON(jsonValue));
    }
    /**
     */
    async updateTwitter(requestParameters) {
        const response = await this.updateTwitterRaw(requestParameters);
        return await response.value();
    }
}
