import { paymentRepository } from '~/repositories';
import { newersAction } from '~/redux/actions';
export default class PaymentService {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }
    async findPaypalUser(email) {
        this.dispatch(newersAction.requestStart());
        const data = await paymentRepository
            .findPaypalUser(email)
            .catch((e) => {
            this.dispatch(newersAction.requestError());
            throw e;
        });
        this.dispatch(newersAction.requestSuccess());
        return data;
    }
    async findStripeUser(email) {
        this.dispatch(newersAction.requestStart());
        const data = await paymentRepository
            .findStripeUser(email)
            .catch((e) => {
            this.dispatch(newersAction.requestError());
            throw e;
        });
        this.dispatch(newersAction.requestSuccess());
        return data;
    }
}
