import { authRepository } from '~/repositories';
import { authAction } from '~/redux/actions';
export default class AuthService {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }
    async login(username, password) {
        this.dispatch(authAction.requestStart());
        await authRepository.login(username, password)
            .catch((e) => {
            this.dispatch(authAction.requestError());
            throw e;
        });
        this.dispatch(authAction.requestSuccess());
    }
}
