import { createSlice } from '@reduxjs/toolkit';
import { TweetDatasModel } from '~/models';
export const initialState = {
    isLoading: false,
    entity: new TweetDatasModel(),
};
const twittersSlice = createSlice({
    name: 'twitter',
    initialState,
    reducers: {
        requestStart(state) {
            state.isLoading = true;
        },
        requestSuccess(state) {
            state.isLoading = false;
        },
        requestError(state) {
            state.isLoading = false;
        },
        settweets(state, action) {
            state.entity = new TweetDatasModel(action.payload);
        },
        removetweet(state, action) {
            state.entity.remove(action.payload);
        },
    },
});
export const twittersAction = twittersSlice.actions;
export default twittersSlice.reducer;
