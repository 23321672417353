/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { CategoryModelFromJSON, PostDataModelFromJSON, } from '../models';
/**
 *
 */
export class WordPressControllerApi extends runtime.BaseAPI {
    /**
     */
    async findCategoriesRaw() {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/wp/dope_zine_lab/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryModelFromJSON));
    }
    /**
     */
    async findCategories() {
        const response = await this.findCategoriesRaw();
        return await response.value();
    }
    /**
     */
    async findPostRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling findPost.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/wp/dope_zine_lab/post/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => PostDataModelFromJSON(jsonValue));
    }
    /**
     */
    async findPost(requestParameters) {
        const response = await this.findPostRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async findPostByCategoriesRaw(requestParameters) {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId', 'Required parameter requestParameters.categoryId was null or undefined when calling findPostByCategories.');
        }
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page', 'Required parameter requestParameters.page was null or undefined when calling findPostByCategories.');
        }
        const queryParameters = {};
        if (requestParameters.categoryId !== undefined) {
            queryParameters['categoryId'] = requestParameters.categoryId;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/wp/dope_zine_lab/posts_by_category`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PostDataModelFromJSON));
    }
    /**
     */
    async findPostByCategories(requestParameters) {
        const response = await this.findPostByCategoriesRaw(requestParameters);
        return await response.value();
    }
}
