/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ImageDataFromJSON, ImageDataToJSON, } from './';
export function UploadDataFromJSON(json) {
    return UploadDataFromJSONTyped(json, false);
}
export function UploadDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'twitterId': json['twitterId'],
        'message': json['message'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'imageList': !exists(json, 'imageList') ? undefined : (json['imageList'].map(ImageDataFromJSON)),
    };
}
export function UploadDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'twitterId': value.twitterId,
        'message': value.message,
        'content': value.content,
        'imageList': value.imageList === undefined ? undefined : (value.imageList.map(ImageDataToJSON)),
    };
}
