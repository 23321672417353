/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function SubscriptionModelFromJSON(json) {
    return SubscriptionModelFromJSONTyped(json, false);
}
export function SubscriptionModelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'subscriptionId': !exists(json, 'subscriptionId') ? undefined : json['subscriptionId'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}
export function SubscriptionModelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'subscriptionId': value.subscriptionId,
        'customerId': value.customerId,
        'email': value.email,
    };
}
