/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
export function TweetDataFromJSON(json) {
    return TweetDataFromJSONTyped(json, false);
}
export function TweetDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'medias': json['medias'],
        'tag': json['tag'],
        'url': json['url'],
        'userName': json['userName'],
    };
}
export function TweetDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'medias': value.medias,
        'tag': value.tag,
        'url': value.url,
        'userName': value.userName,
    };
}
