import { createSlice } from '@reduxjs/toolkit';
import { ImagesModel } from '~/models/ImagesModel';
export const initialState = {
    isLoading: false,
    entity: new ImagesModel(),
};
const uploadSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        init(state) {
            state.entity = new ImagesModel();
        },
        requestStart(state) {
            state.isLoading = true;
        },
        requestSuccess(state) {
            state.isLoading = false;
        },
        requestError(state) {
            state.isLoading = false;
        },
        addImage(state, action) {
            state.entity.add(action.payload);
        },
        removeImage(state, action) {
            state.entity.remove(action.payload);
        },
    },
});
export const uploadAction = uploadSlice.actions;
export default uploadSlice.reducer;
