import { immerable } from 'immer';
import { PostModel, PostModelProps } from './PostModel';
export { PostModelProps };
export class PostsModel {
    constructor(props) {
        props
            ? (this._list = new Map(props.posts.map((val) => [val.id, new PostModel(val)])))
            : (this._list = new Map());
        this[immerable] = true;
    }
    get list() {
        return Array.from(this._list).map(([_, val]) => ({
            id: val.id,
            title: val.title,
            content: val.content,
            featuredImageUrl: val.featuredImageUrl,
            authorId: val.authorId,
            authorName: val.authorName,
            category: val.category
        }));
    }
    add(data) {
        data.posts.forEach((val) => this._list.set(val.id, new PostModel(val)));
    }
}
