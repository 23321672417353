import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import Loading from './components/atoms/Loading';
import { useServices } from './hooks';
const AdminRoute = props => {
    const RenderLoading = () => React.createElement(Loading, null);
    const { exact = false, path, component: page } = props;
    const user = useSelector((state) => state.user.entity);
    const { userService } = useServices();
    const history = useHistory();
    useEffect(() => {
        userService.initUser().then((data) => {
            if (data.role === 0) {
                history.replace('/');
            }
        })
            .catch((e) => {
            history.replace('/login');
        });
    }, []);
    return user ? (React.createElement(Route, Object.assign({}, props))) : (React.createElement(Route, { exact: exact, path: path, component: RenderLoading }));
};
export default AdminRoute;
