/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function WithdrawalFormFromJSON(json) {
    return WithdrawalFormFromJSONTyped(json, false);
}
export function WithdrawalFormFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'labTwitter': !exists(json, 'labTwitter') ? undefined : json['labTwitter'],
        'userId': json['userId'],
    };
}
export function WithdrawalFormToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'labTwitter': value.labTwitter,
        'userId': value.userId,
    };
}
