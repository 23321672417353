import { Configuration, PaymentControllerApi, PayPalSubscriber, SubscriptionModel } from '~/lib/apiClient';
export { PayPalSubscriber, SubscriptionModel };
const config = new Configuration({
    basePath: process.env.API_URL,
});
const paymentApi = new PaymentControllerApi(config);
export const paymentRepository = {
    findPaypalUser: (email) => paymentApi.findPaypalUser({ email: email }),
    findStripeUser: (email) => paymentApi.findStripeUser({ email: email }),
};
