import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    message: undefined,
};
const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        set(state, action) {
            state.message = action.payload.message;
        },
        clear(state) {
            state.message = undefined;
        },
    },
});
export const errorAction = errorSlice.actions;
export default errorSlice.reducer;
