/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
export function TwitterUserDataFromJSON(json) {
    return TwitterUserDataFromJSONTyped(json, false);
}
export function TwitterUserDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'followersCount': json['followersCount'],
        'id': json['id'],
        'uk': json['uk'],
        'userName': json['userName'],
    };
}
export function TwitterUserDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'followersCount': value.followersCount,
        'id': value.id,
        'uk': value.uk,
        'userName': value.userName,
    };
}
