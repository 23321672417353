import { immerable } from 'immer';
export class TweetDataModel {
    constructor({ id, userName, tag, url, medias }) {
        this[immerable] = true;
        this.id = id;
        this.userName = userName;
        this.tag = tag;
        this.url = url;
        this.medias = medias;
    }
}
