import { immerable } from 'immer';
import { FollowerModel, FollowerModelProps } from './FollowerModel';
export { FollowerModelProps };
export class FollowersModel {
    constructor(props) {
        props
            ? (this._list = new Map(props.followers.map((follower) => [
                follower.date,
                new FollowerModel(follower),
            ])))
            : (this._list = new Map());
        this[immerable] = true;
    }
    get list() {
        return Array.from(this._list).map(([_, follower]) => ({
            id: follower.id,
            follows: follower.follows,
            date: follower.date,
            uk: follower.uk,
        }));
    }
    addUser(follower) {
        this._list.set(follower.id, new FollowerModel(follower));
    }
}
