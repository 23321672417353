import { immerable } from 'immer';
import { ImageModel, ImageModelProps } from './ImageModel';
export { ImageModelProps };
export class ImagesModel {
    constructor(props) {
        props
            ? (this._list = new Map(props.images.map((image) => [image.id, new ImageModel(image)])))
            : (this._list = new Map());
        this[immerable] = true;
    }
    get list() {
        return Array.from(this._list).map(([_, image]) => ({
            id: image.id,
            img: image.img,
            fileSize: image.fileSize,
        }));
    }
    add(image) {
        this._list.set(image.id, new ImageModel(image));
    }
    remove(id) {
        this._list.delete(id);
    }
}
