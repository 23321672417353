import { immerable } from 'immer';
export class UserDataModel {
    constructor({ userId, emailAddress, userName, country, labId, labTwitterId, twitterId, twitterUK, instagramId, role }) {
        this[immerable] = true;
        this.userId = userId;
        this.emailAddress = emailAddress;
        this.userName = userName;
        this.country = country;
        this.labId = labId;
        this.labTwitterId = labTwitterId;
        this.twitterId = twitterId;
        this.twitterUK = twitterUK;
        this.instagramId = instagramId;
        this.role = role;
    }
}
