import { immerable } from 'immer';
import { TweetDataModel, TweetDataModelProps } from './TweetDataModel';
export { TweetDataModelProps };
export class TweetDatasModel {
    constructor(props) {
        props
            ? (this._list = new Map(props.tweets.map((tweet) => [tweet.id, new TweetDataModel(tweet)])))
            : (this._list = new Map());
        this[immerable] = true;
    }
    get list() {
        return Array.from(this._list).map(([_, tweet]) => ({
            id: tweet.id,
            username: tweet.userName,
            tag: tweet.tag,
            url: tweet.url,
            medias: tweet.medias,
        }));
    }
    remove(id) {
        this._list.delete(id);
    }
}
