import { immerable } from 'immer';
import { MemberModel, MemberModelProps } from './MemberModel';
export { MemberModelProps };
export class MembersModel {
    constructor(props) {
        props
            ? (this._list = new Map(props.members.map((member) => [
                member.userId,
                new MemberModel(member),
            ])))
            : (this._list = new Map());
        this[immerable] = true;
    }
    get list() {
        return Array.from(this._list).map(([_, member]) => ({
            userId: member.userId,
            userName: member.userName,
            country: member.country,
            labTwitterId: member.labTwitterId,
            twitterId: member.twitterId,
            instagramId: member.instagramId
        }));
    }
}
