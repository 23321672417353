/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function UserModelFromJSON(json) {
    return UserModelFromJSONTyped(json, false);
}
export function UserModelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'labId': !exists(json, 'labId') ? undefined : json['labId'],
        'openChatUserName': !exists(json, 'openChatUserName') ? undefined : json['openChatUserName'],
        'labTwitterId': !exists(json, 'labTwitterId') ? undefined : json['labTwitterId'],
        'labTwitterAccessToken': !exists(json, 'labTwitterAccessToken') ? undefined : json['labTwitterAccessToken'],
        'labTwitterSecretToken': !exists(json, 'labTwitterSecretToken') ? undefined : json['labTwitterSecretToken'],
        'paymentMethod': !exists(json, 'paymentMethod') ? undefined : json['paymentMethod'],
        'paypalTransactionId': !exists(json, 'paypalTransactionId') ? undefined : json['paypalTransactionId'],
        'paypalEmail': !exists(json, 'paypalEmail') ? undefined : json['paypalEmail'],
        'stripeTransactionId': !exists(json, 'stripeTransactionId') ? undefined : json['stripeTransactionId'],
        'stripeCustomerId': !exists(json, 'stripeCustomerId') ? undefined : json['stripeCustomerId'],
        'stripeEmail': !exists(json, 'stripeEmail') ? undefined : json['stripeEmail'],
        'etcMemo': !exists(json, 'etcMemo') ? undefined : json['etcMemo'],
        'twitterId': !exists(json, 'twitterId') ? undefined : json['twitterId'],
        'twitterUK': !exists(json, 'twitterUK') ? undefined : json['twitterUK'],
        'instagramId': !exists(json, 'instagramId') ? undefined : json['instagramId'],
        'role': !exists(json, 'role') ? undefined : json['role'],
    };
}
export function UserModelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userId': value.userId,
        'password': value.password,
        'emailAddress': value.emailAddress,
        'userName': value.userName,
        'country': value.country,
        'labId': value.labId,
        'openChatUserName': value.openChatUserName,
        'labTwitterId': value.labTwitterId,
        'labTwitterAccessToken': value.labTwitterAccessToken,
        'labTwitterSecretToken': value.labTwitterSecretToken,
        'paymentMethod': value.paymentMethod,
        'paypalTransactionId': value.paypalTransactionId,
        'paypalEmail': value.paypalEmail,
        'stripeTransactionId': value.stripeTransactionId,
        'stripeCustomerId': value.stripeCustomerId,
        'stripeEmail': value.stripeEmail,
        'etcMemo': value.etcMemo,
        'twitterId': value.twitterId,
        'twitterUK': value.twitterUK,
        'instagramId': value.instagramId,
        'role': value.role,
    };
}
/**
* @export
* @enum {string}
*/
export var UserModelPaymentMethodEnum;
(function (UserModelPaymentMethodEnum) {
    UserModelPaymentMethodEnum["PAYPAL"] = "PAYPAL";
    UserModelPaymentMethodEnum["STRIPE"] = "STRIPE";
    UserModelPaymentMethodEnum["ETC"] = "ETC";
})(UserModelPaymentMethodEnum || (UserModelPaymentMethodEnum = {}));
