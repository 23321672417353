import { errorAction } from '~/redux/actions';
export default class ErrorService {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }
    setError({ message }) {
        this.dispatch(errorAction.set({ message }));
    }
    clearError() {
        this.dispatch(errorAction.clear());
    }
}
