import { uploadAction } from '~/redux/actions';
import { uploadRepository, } from '~/repositories/uploadRepository';
export default class UploadService {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }
    init() {
        this.dispatch(uploadAction.init());
    }
    addImage(id, img, fileSize) {
        this.dispatch(uploadAction.addImage({ id: id, img: img, fileSize: fileSize }));
    }
    removeImage(id) {
        this.dispatch(uploadAction.removeImage(id));
    }
    async upload(param) {
        this.dispatch(uploadAction.requestStart());
        await uploadRepository.post(param).catch((e) => {
            this.dispatch(uploadAction.requestError());
            throw e;
        });
        this.dispatch(uploadAction.requestSuccess());
    }
    async findTag() {
        this.dispatch(uploadAction.requestStart());
        const tag = await uploadRepository.findTag().catch((e) => {
            this.dispatch(uploadAction.requestError());
            throw e;
        });
        this.dispatch(uploadAction.requestSuccess());
        return tag;
    }
}
