import { Configuration, TwitterControllerApi } from '~/lib/apiClient';
const config = new Configuration({
    basePath: process.env.API_URL,
});
const twitterApi = new TwitterControllerApi(config);
export const twitterRepository = {
    getTwitterAcc: (id) => twitterApi.user({ screenName: id }),
    getTimeLine: () => twitterApi.timeline(),
    removeTweet: (id) => twitterApi.removePost({ id }),
    memberFollowCheck: () => twitterApi.memberFollowCheck(),
    memberFollow: () => twitterApi.memberFollow(),
};
