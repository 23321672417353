import { wordPressRepository } from '~/repositories';
import { wordPresssAction } from '~/redux/actions';
export default class WordPressService {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }
    init() {
        this.dispatch(wordPresssAction.init());
    }
    async findPost(id) {
        this.dispatch(wordPresssAction.requestStart());
        const data = await wordPressRepository
            .findPost(id)
            .catch((e) => {
            this.dispatch(wordPresssAction.requestError());
            throw e;
        });
        this.dispatch(wordPresssAction.requestSuccess());
        return data;
    }
    async findCategories() {
        this.dispatch(wordPresssAction.requestStart());
        const data = await wordPressRepository
            .findCategories()
            .catch((e) => {
            this.dispatch(wordPresssAction.requestError());
            throw e;
        });
        this.dispatch(wordPresssAction.setCategories({ categories: data.map((elm) => {
                return {
                    id: elm.id ? elm.id : '',
                    count: elm.count ? elm.count : 0,
                    name: elm.name ? elm.name : '',
                };
            }) }));
        this.dispatch(wordPresssAction.requestSuccess());
    }
    async findPostByCategories(categoryId, page) {
        this.dispatch(wordPresssAction.requestStart());
        const data = await wordPressRepository
            .findPostByCategories(categoryId, page)
            .catch((e) => {
            this.dispatch(wordPresssAction.requestError());
            throw e;
        });
        if (page > 1) {
            this.dispatch(wordPresssAction.addPosts({ posts: data.map((elm) => {
                    return {
                        id: elm.id ? elm.id : '',
                        title: elm.title ? elm.title : '',
                        content: elm.content ? elm.content : '',
                        featuredImageUrl: elm.featuredImageUrl ? elm.featuredImageUrl : '',
                        authorId: elm.authorId ? elm.authorId : '',
                        authorName: elm.authorName ? elm.authorName : '',
                        category: elm.category ? elm.category : ''
                    };
                }) }));
        }
        else {
            this.dispatch(wordPresssAction.setPosts({ posts: data.map((elm) => {
                    return {
                        id: elm.id ? elm.id : '',
                        title: elm.title ? elm.title : '',
                        content: elm.content ? elm.content : '',
                        featuredImageUrl: elm.featuredImageUrl ? elm.featuredImageUrl : '',
                        authorId: elm.authorId ? elm.authorId : '',
                        authorName: elm.authorName ? elm.authorName : '',
                        category: elm.category ? elm.category : ''
                    };
                }) }));
        }
        this.dispatch(wordPresssAction.requestSuccess());
        return data;
    }
    setPostId(id) {
        this.dispatch(wordPresssAction.setPostId(id));
    }
}
