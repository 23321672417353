/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function UserDisplayModelFromJSON(json) {
    return UserDisplayModelFromJSONTyped(json, false);
}
export function UserDisplayModelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'labTwitterId': !exists(json, 'labTwitterId') ? undefined : json['labTwitterId'],
        'twitterId': !exists(json, 'twitterId') ? undefined : json['twitterId'],
        'instagramId': !exists(json, 'instagramId') ? undefined : json['instagramId'],
    };
}
export function UserDisplayModelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userId': value.userId,
        'userName': value.userName,
        'country': value.country,
        'labTwitterId': value.labTwitterId,
        'twitterId': value.twitterId,
        'instagramId': value.instagramId,
    };
}
