/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { UploadDataToJSON, UploadResponseFromJSON, } from '../models';
/**
 *
 */
export class UploadControllerApi extends runtime.BaseAPI {
    /**
     */
    async findTagRaw() {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/upload/tag`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async findTag() {
        const response = await this.findTagRaw();
        return await response.value();
    }
    /**
     */
    async sendRaw(requestParameters) {
        if (requestParameters.uploadData === null || requestParameters.uploadData === undefined) {
            throw new runtime.RequiredError('uploadData', 'Required parameter requestParameters.uploadData was null or undefined when calling send.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/upload/send`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UploadDataToJSON(requestParameters.uploadData),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => UploadResponseFromJSON(jsonValue));
    }
    /**
     */
    async send(requestParameters) {
        const response = await this.sendRaw(requestParameters);
        return await response.value();
    }
}
