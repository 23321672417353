import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '~/redux/store';
import { enableMapSet } from 'immer';
import GlobalStyle from '~/styles/GlobalStyle';
import GuardedRoute from './GuardedRoute';
import AdminRoute from './AdminRoute';
enableMapSet();
const Login = React.lazy(() => import('~/components/pages/Login'));
const Home = React.lazy(() => import('~/components/pages/Home'));
const Upload = React.lazy(() => import('~/components/pages/Upload'));
const Follower = React.lazy(() => import('~/components/pages/Follower'));
const MemberFollow = React.lazy(() => import('~/components/pages/MemberFollow'));
const ConfirmPost = React.lazy(() => import('~/components/pages/ConfirmPost'));
const Admin = React.lazy(() => import('~/components/pages/Admin'));
const Withdrawal = React.lazy(() => import('~/components/pages/Withdrawal'));
const Newer = React.lazy(() => import('~/components/pages/Newer'));
const LabAcconntRequest = React.lazy(() => import('~/components/pages/LabAcconntRequest'));
const Posts = React.lazy(() => import('~/components/pages/Posts'));
const Schedule = React.lazy(() => import('~/components/pages/Schedule'));
const Profile = React.lazy(() => import('~/components/pages/Profile'));
const Member = React.lazy(() => import('~/components/pages/Member'));
const WithdrawalCs = React.lazy(() => import('~/components/pages/WithdrawalCs'));
const LabSite = React.lazy(() => import('~/components/pages/LabSite'));
ReactDOM.render(React.createElement(Provider, { store: store },
    React.createElement(GlobalStyle, null),
    React.createElement(Router, null,
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: '/login' },
                React.createElement(Suspense, { fallback: null },
                    React.createElement(Login, null))),
            React.createElement(Route, { exact: true, path: '/newer' },
                React.createElement(Suspense, { fallback: null },
                    React.createElement(Newer, null))),
            React.createElement(Route, { exact: true, path: '/withdrawal_cs' },
                React.createElement(Suspense, { fallback: null },
                    React.createElement(WithdrawalCs, null))),
            React.createElement(AdminRoute, { exact: true, path: '/admin' },
                React.createElement(Suspense, { fallback: null },
                    React.createElement(Admin, null))),
            React.createElement(AdminRoute, { exact: true, path: '/withdrawal' },
                React.createElement(Suspense, { fallback: null },
                    React.createElement(Withdrawal, null))),
            React.createElement(GuardedRoute, { exact: true, path: '/' },
                React.createElement(Suspense, { fallback: null },
                    React.createElement(Home, null))),
            React.createElement(GuardedRoute, { exact: true, path: '/profile' },
                React.createElement(Suspense, { fallback: null },
                    React.createElement(Profile, null))),
            React.createElement(GuardedRoute, { exact: true, path: '/upload' },
                React.createElement(Suspense, { fallback: null },
                    React.createElement(Upload, null))),
            React.createElement(GuardedRoute, { exact: true, path: '/follower' },
                React.createElement(Suspense, { fallback: null },
                    React.createElement(Follower, null))),
            React.createElement(GuardedRoute, { exact: true, path: '/post_confirm' },
                React.createElement(Suspense, { fallback: null },
                    React.createElement(ConfirmPost, null))),
            React.createElement(GuardedRoute, { exact: true, path: '/member_follow' },
                React.createElement(Suspense, { fallback: null },
                    React.createElement(MemberFollow, null))),
            React.createElement(GuardedRoute, { exact: true, path: '/lab_ac_request' },
                React.createElement(Suspense, { fallback: null },
                    React.createElement(LabAcconntRequest, null))),
            React.createElement(GuardedRoute, { exact: true, path: '/posts' },
                React.createElement(Suspense, { fallback: null },
                    React.createElement(Posts, null))),
            React.createElement(GuardedRoute, { exact: true, path: '/schedule' },
                React.createElement(Suspense, { fallback: null },
                    React.createElement(Schedule, null))),
            React.createElement(GuardedRoute, { exact: true, path: '/member' },
                React.createElement(Suspense, { fallback: null },
                    React.createElement(Member, null))),
            React.createElement(GuardedRoute, { exact: true, path: '/dzlw' },
                React.createElement(Suspense, { fallback: null },
                    React.createElement(LabSite, null)))))), document.getElementById('root'));
