import { immerable } from 'immer';
export class TwitterModel {
    constructor({ id, uk, userName, followersCount }) {
        this[immerable] = true;
        this.id = id;
        this.uk = uk;
        this.userName = userName;
        this.followersCount = followersCount;
    }
}
