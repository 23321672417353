import { createSlice } from '@reduxjs/toolkit';
import { WithdrawalsModel } from '~/models/WithdrawalsModel';
export const initialState = {
    isLoading: false,
    entity: new WithdrawalsModel
};
const withdrawalsSlice = createSlice({
    name: 'withdrawal',
    initialState,
    reducers: {
        requestStart(state) {
            state.isLoading = true;
        },
        requestSuccess(state) {
            state.isLoading = false;
        },
        requestError(state) {
            state.isLoading = false;
        },
        fetch(state, action) {
            state.entity = new WithdrawalsModel(action.payload);
        },
    },
});
export const withdrawalsAction = withdrawalsSlice.actions;
export default withdrawalsSlice.reducer;
