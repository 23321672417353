import { Configuration, UploadControllerApi, SendRequest, } from '~/lib/apiClient';
export { SendRequest };
const config = new Configuration({
    basePath: process.env.API_URL,
});
const uploadApi = new UploadControllerApi(config);
export const uploadRepository = {
    post: (param) => uploadApi.send(param),
    findTag: () => uploadApi.findTag(),
};
