import { immerable } from 'immer';
export class FollowerModel {
    constructor({ id, follows, date, uk }) {
        this[immerable] = true;
        this.id = id;
        this.follows = follows;
        this.date = date;
        this.uk = uk;
    }
}
