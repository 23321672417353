import { immerable } from 'immer';
export class ScheduleModel {
    constructor({ id, date, start, end, userName, title, content, zoomUrl, zoomId, zoomPass }) {
        this[immerable] = true;
        this.id = id;
        this.date = date;
        this.start = start;
        this.end = end;
        this.userName = userName;
        this.title = title;
        this.content = content;
        this.zoomUrl = zoomUrl;
        this.zoomId = zoomId;
        this.zoomPass = zoomPass;
    }
}
