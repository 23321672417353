import { followerRepository } from '~/repositories';
import { followersAction } from '~/redux/actions';
export default class FollowerService {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }
    initFollower() {
        this.dispatch(followersAction.init());
    }
    async fetchFollower(id) {
        this.dispatch(followersAction.requestStart());
        const data = await followerRepository.getFollower(id).catch((e) => {
            this.dispatch(followersAction.requestError());
            throw e;
        });
        this.dispatch(followersAction.setFollowers({ followers: data.list }));
        this.dispatch(followersAction.requestSuccess());
    }
}
